
import Vue from 'vue';
import utils from '../../util';
import { Product, Campaign, AdvertiserFilter } from '../../types/advertisers';
import { format } from 'date-fns';
import { AdvertiserDataApi } from '../../store/modules/customer/types';

export default Vue.extend({
  name: 'AdvertiserDetails',
  props: ['advertisersOnly'],
  components: {
    AdvertiserList: () => import('./components/advertiserList.vue'),
    ProductList: () => import('./components/productList.vue'),
    CampaignList: () => import('./components/campaignList.vue'),
    AdvertisersForOrderList: () => import('./components/advertiserForOrders.vue'),
  },
  data: (): {
    selectedAdvertiser: string | null;
    selectedProduct: string | null;
    selectedAdvertiserName: string | null;
    search: string;
    tacticData: object[];
    selectedPartners: object | null;
    partnerFilter: AdvertiserFilter;
    productList: object[] | null;
    campaignList: object[] | null;
    campaignsLoading: boolean;
    offset: number;
  } => ({
    selectedAdvertiser: null,
    selectedProduct: null,
    selectedAdvertiserName: null,
    search: '',
    tacticData: [],
    partnerFilter: { DisplayName: null, Elements: [], Key: null },
    selectedPartners: null,
    productList: null,
    campaignList: null,
    campaignsLoading: false,
    offset: 0,
  }),
  created(): void {
    this.getTactics();
    this.getAdvertisers();
  },
  mounted(): void {
    this.clear();
  },
  computed: {
    clientList(): AdvertiserDataApi[] | null {
      if (!this.$store.state.linearAdmin?.linearAdminClients) {
        return null;
      }
      return this.$store.state.linearAdmin.linearAdminClients;
    },
    error(): string {
      return this.friendlyError(this.$store.state.linearAdmin?.linearAdminError);
    },
    superUser(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SU']);
    },
    summarySection() {
      const filteredLayout = this.tacticData.filter(section => section.id === 'summary');
      return filteredLayout?.length > 0 ? filteredLayout[0] : null;
    },
  },
  methods: {
    currentDate(): string {
      return format(new Date(), 'yyyy-MM-dd');
    },
    friendlyError(err: string): string {
      if (err?.includes('lookupAdvertiser')) {
        return 'No advertisers found';
      } else if (err?.includes('getCampaigns')) {
        return 'No campaigns found for this advertiser';
      } else if (err?.includes('getProductsForAdvertiser')) {
        return 'No products found for this advertiser';
      }
      return err;
    },
    async paginateOrders(params) {
      await this.clear();
      this.search = params.advertiser ?? '';
      this.offset = params.offset ?? 0;
      this.getAdvertisers();
    },
    async updateSearch(advertiser: string): Promise<void> {
      this.search = '';
      await this.clear();
      this.search = advertiser;
      this.getAdvertisers();
    },
    async changePartner(selectedPartners): Promise<void> {
      this.selectedPartners = selectedPartners;
      await this.clear();
      this.getAdvertisers();
    },
    async clear(): Promise<void> {
      // reset all selections
      this.productList = null;
      this.campaignList = null;
      this.selectedAdvertiser = null;
      this.selectedProduct = null;
      await this.$store.dispatch('advertiserView/reset');
      return;
    },
    async getTactics(): Promise<void> {
      if (!this.$store.state.customer?.tacticList) {
        await this.$store
          .dispatch('getTacticsGQL')
          .then(data => {
            this.tacticData = data;
          })
          .catch((error: Error) => {
            console.log(error); // eslint-disable-line no-console
          });
      } else {
        this.tacticData = this.$store.state.customer.tacticList;
      }
    },
    async getAdvertisers(): Promise<void> {
      await this.$store
        .dispatch('linearAdmin/getLinearAdminClientsSearch', {
          name: this.search,
          partners: this.selectedPartners?.value ? this.selectedPartners.value : '',
          limit: this.advertisersOnly ? 10 : 100,
          offset: this.offset,
        })
        .catch();
      this.updateList();
    },
    async advertiserSelect(advertiser: AdvertiserDataApi): Promise<void> {
      await this.clear();
      this.getCampaignsDataApi(advertiser.id);
      this.selectedAdvertiser = advertiser.id;
      this.selectedAdvertiserName = advertiser.name;
    },
    async getCampaignsDataApi(id: string): Promise<void> {
      this.campaignsLoading = true;
      await this.$store
        .dispatch('getCampaigns', {
          advertiserId: id,
          daterange: 'alltime',
          cache: false,
        })
        .then((camps: { data: Campaigns }) => {
          // get a array of the campaign types
          if (camps?.data?.CampTypes && camps?.data?.campaignlist) {
            this.productList = camps.data?.CampTypes?.map(x => {
              return { id: x.label };
            });
            this.campaignList = camps.data?.campaignlist;
          } else {
            this.productList = [];
            this.campaignList = [];
          }
        })
        .catch((e) => {
          console.log({e});
        })
        .finally(() => (this.campaignsLoading = false));
    },
    productSelect(id: string): void {
      this.selectedProduct = id;
    },
    openAdvertiser(advertiser: AdvertiserDataApi): void {
      const query = {
        id: advertiser.id,
        tab: 'orderlist',
      };
      // open new window to avoid losing context
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
    openTactic(product: Product): void {
      const query = {
        id: this.selectedAdvertiser,
        tab: product.id.toLowerCase(),
      };
      // open new window to avoid losing context
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
    selectedCampaign(campaign: Campaign): void {
      const query = {
        id: this.selectedAdvertiser,
        tab: this.selectedProduct.toLowerCase() === 'siteimpact' ? 'emailsi' : this.selectedProduct.toLowerCase(),
        viewCampaigns: campaign.id,
      };
      // open new window to avoid losing context
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
    filterList(arr, key): Array<AdvertiserFilter> {
      if (!arr) {
        return [];
      }
      const filters = Array.isArray(arr) ? arr : [arr];
      return filters.find(filter => filter.Key.toLowerCase() === key);
    },
    updateList(): void {
      this.partnerFilter = this.filterList(this.$store.state.linearAdmin?.linearAdminClients?.facets, 'agencypartner');
    },
  },
});
